<template>
  <div class="page">
    <el-form :model="formInfo" label-width="120px">
      <el-card>
        <template slot="header">
          <span class="title">基础信息</span>
          <div class="button-back">
            <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
              <img src="@/assets/back.png" alt="">
            </el-button>
          </div>
        </template>
        <div class="card-body">
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="所属商城">
                <el-input size="small" v-model="formInfo.shop_name" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="客户名称">
                <el-input size="small" v-model="formInfo.client_name" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="代理名称">
                <el-input size="small" v-model="formInfo.agent_name" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联系人名称">
                <el-input size="small" v-model="formInfo.link_name" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="联系电话">
                <el-input size="small" v-model="formInfo.link_phone" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="销售人员">
                <el-input size="small" v-model="formInfo.sell_name" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="制单人名称">
                <el-input size="small" v-model="formInfo.make_order_name" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="客户地址">
            <el-input size="small" v-model="formInfo.client_addr" disabled />
          </el-form-item>
        </div>
      </el-card>
      <el-card>
        <template slot="header">
          <span class="title">卡券信息</span>
        </template>
        <div class="card-body-1">
          <el-form-item label="卡券信息">
            <table border>
              <tr>
                <th width="15%">卡券名称</th>
                <th width="200px">面值</th>
                <th width="30%">卡号段</th>
                <th width="200px">数量</th>
                <th width="150px">折扣率(%)</th>
                <th width="200px">折扣金额</th>
                <th width="200px">优惠后金额</th>
              </tr>
              <tr v-for="(item, index) in formInfo.cardList" :key="index">
                <td class="card-name">
                  {{ item.card_name }}
                </td>
                <td>
                  <span>
                    {{ item.card_value }}
                  </span>
                </td>
                <td class="card-section">
                  <div v-for="(sec, sec_index) in item.card_range_list" :key="sec_index" style="display: flex; align-items: center; margin-bottom: 4px;">
                    <el-input v-model="sec.card_code_start" disabled /> -
                    <el-input v-model="sec.card_code_end" disabled />
                  </div>
                </td>
                <td>
                  {{ item.card_num }}
                </td>
                <td>
                  {{ item.discount_ratio }}
                </td>
                <td>
                  {{ item.discount_value }}
                </td>
                <td>
                  {{ item.money }}
                </td>
              </tr>
            </table>
          </el-form-item>
          <el-row style="width: 55%;">
            <el-col :span="4">
              <el-form-item label="合计总金额">
                <el-input size="small" style="width: 200px;" v-model="formInfo.total_price" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="11">
              <el-form-item label="优惠后总金额">
                <el-input size="small" style="width: 200px;" v-model="formInfo.after_discount_price" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注信息">
            <el-input
              size="small"
              type="textarea"
              placeholder="请输入内容"
              v-model="formInfo.remark"
              rows="5"
              disabled
            />
          </el-form-item>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import * as API_saleCard from '@/api/saleCard'

export default {
  name: "saleOrderDetail",
  data() {
    return {
      isEdit: !!this.$route.query.id,
      id: this.$route.query.id,
      formInfo: {
        shop_id: '',
        shop_name: '',
        client_name: '',
        agent_name: '',
        link_name: '',
        link_phone: '',
        client_addr: '',
        sell_name: '',
        make_order_name: '',
        cardList: [
          {
            card_name: '',
            card_value: '',
            card_range_list: [
              {
                card_code_start: '',
                card_code_end: ''
              }
            ],
            card_num: 0,
            discount_ratio: '',
            discount_value: '',
            money: ''
          }
        ],
        total_price: 0,
        after_discount_price: 0,
        remark: ''
      },
    }
  },
  mounted() {
    if (this.isEdit) {
      API_saleCard.getSaleOrderDetail(this.id).then(res => {
        this.formInfo = res.trade_sell_do
        this.formInfo['cardList'] = res.trade_sell_card_dos.reduce((pre, cur) => {
          if (pre.length) {
            let matchIndex = 0;
            if (pre.some((item, index) => {matchIndex = index; return item.card_id === cur.card_id})) {
              pre[matchIndex]['card_num'] += cur.card_num;
              const total = cur.card_value * pre[matchIndex]['card_num'];
              pre[matchIndex]['card_range_list'].push({
                card_code_start: cur.card_code_start,
                card_code_end: cur.card_code_end,
              });
              pre[matchIndex]['discount_value'] = (total * (1 - (cur.discount_ratio / 100)));
              pre[matchIndex]['money'] = total - pre[matchIndex]['discount_value'];
            } else {
              let obj = {}
              const total = cur.card_value * cur.card_num
              obj['card_name'] = cur.card_name
              obj['card_value'] = cur.card_value
              obj['card_num'] = cur.card_num
              obj['discount_ratio'] = cur.discount_ratio
              obj['discount_value'] = (total * (1 - (cur.discount_ratio / 100)))
              obj['money'] = total - obj['discount_value']
              obj['shop_id'] = cur.shop_id
              obj['card_id'] = cur.card_id
              obj['card_range_list'] = [
                {
                  card_code_start: cur.card_code_start,
                  card_code_end: cur.card_code_end,
                }
              ]
              pre.push(obj)
            }
          } else {
            let obj = {}
            const total = cur.card_value * cur.card_num
            obj['card_name'] = cur.card_name
            obj['card_value'] = cur.card_value
            obj['card_num'] = cur.card_num
            obj['discount_ratio'] = cur.discount_ratio
            obj['discount_value'] = (total * (1 - (cur.discount_ratio / 100))).toFixed(2)
            obj['money'] = total - obj['discount_value']
            obj['shop_id'] = cur.shop_id;
            obj['card_id'] = cur.card_id;
            obj['card_range_list'] = [
              {
                card_code_start: cur.card_code_start,
                card_code_end: cur.card_code_end,
              }
            ]
            pre.push(obj)
          }
          return pre
        }, []);
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card:nth-child(1) {
    /deep/ .el-card__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 20px;
      .button-back {
        height: 40px;
        .back_btn {
          width: 36px;
          height: 36px;
          background-color: #1A43A9;
          border-radius: 50%;
          img {
            width: 10px;
            height: 20px;
            position: relative;
            top: -2px;
            left: -4px;
          }
        }
      }
    }
  }
  .el-card {
    /deep/ .el-card__header {
      .title:before {
        content: " ";
        display: inline-block;
        position: relative;
        left: -20px;
        width: 5px;
        height: 20px;
        background: #1A43A9;
        transform: translate(0, 20%);
      }
    }
    margin-bottom: 20px;
    .card-body-1 {
      .el-form-item {
        .el-form-item__content {
          .left {
            width: 200px;
          }
          .right {
            position: absolute;
            top: 0;
            left: 240px;
            width: 600px;
          }
        }
      }
    }
    /deep/ .el-form-item__content {
      table {
        border: 1px solid #E6ECF7;
        tr > th {
          text-align: center;
          background-color: #E6ECF7;
        }
        tr > td {
          padding: 10px 5px;
          text-align: center;
          input {
            width: 100%;
          }
          .el-input__inner {
            padding: 0 5px;
          }
        }
        tr {
          .card-name, .card-section {
            min-width: 200px;
            .icon-btn {
              border: 0;
              padding: 0;
              margin-left: 0;
              .el-icon-circle-plus-outline, .el-icon-remove-outline {
                font-size: 22px;
              }
            }
          }
          .card-name {
            .warn-span {
              position: absolute;
              top: 80px;
              left: 100px;
              font-size: 14px;
              color: red;
            }
          }
        }
      }
    }
  }
}

</style>
